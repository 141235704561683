.full-cover {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.page-name {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 40px;
}

@media (max-width: 480px) {
  .page-name {
    font-size: 18px;
  }
}