.direction {
    font-weight: bold;
    text-transform: uppercase;
    height: calc(18.75vh - 12.5px);
    max-height: 198px;
    min-height: 150px;
    font-size: 128px;
    display: flex;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
    transition: margin .25s ease;
}

.direction:first-child {
    margin-top: 50px;
}

@media (max-height: 860px) {
    .direction {
        font-size: 96px;
        min-height: 100px;
    }
}

@media (max-width: 480px) {
    .direction {
        font-size: 56px;
        min-height: 100px;
        max-height: 150px;
        margin-top: 12.5px;
        justify-content: center;
    }

    .direction:first-child {
        margin-top: 25px;
    }
}

@media (max-width: 320px) {
    .direction {
        font-size: 48px;
    }  
}

