.bottom-row {
    display: inline-flex;
    margin-left: 12px;
    margin-right: 12px;
    width: calc(100vw - 24px);
    align-items: center;
    position: absolute;
    bottom: 3px;
    left: 0;    
}

.bottom-row *:first-child, .bottom-row *:last-child {
    flex: 1;
}
