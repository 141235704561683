.navigation-arrow {
    cursor: pointer;
    font-size:64px;
}

.label {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
}

.direction-arrow {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 480px) {
    .navigation-arrow {
        font-size: 48px;
    }

    .label {
        /* font-size: 12px; */
        display: none;
    }
}