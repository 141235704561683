.language-switch {
    overflow: hidden;
    display: flex;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    height: min-content;
    align-items: flex-end;
    flex-direction: column;
    height: 47px;
}

.language-switch * {
    position: relative;
    cursor: pointer;
    transition: 0.15s top cubic-bezier(0, 0.55, 0.45, 1);
    user-select: none;
}

.language-switch *::selection {
    background: none;
}

.en-language-label {
    top: 5px;
}

@media (max-width: 480px) {
    .language-switch {
        font-size: 24px;
        height: 29px;
    }
}